import React from 'react';
import PropTypes from 'prop-types';
import { debounce, startCase } from 'lodash';

import Form from 'react-bootstrap/Form';
import MaskedInput from 'react-text-mask';

import Select from 'components/elements/FormInput/select/Select';
import DatePicker from 'components/elements/FormInput/date/DatePicker';
import BurnCenterFilter from 'components/elements/BurnCenterFilter';

import { DataUploadLabels, DataUploadShortLabels } from 'utils/enums';

const MaskedDateInput = React.forwardRef((inputProps, ref) => (
  <MaskedInput
    {...inputProps}
    ref={ref}
    mask={[
      // Month: 01-12
      /[0-1]/,
      /\d/,
      '/',
      // Day: 01-31
      /[0-3]/,
      /\d/,
      '/',
      // Year
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
  />
));

const typeOptions = Object.entries(DataUploadLabels)
  .filter(([key]) => key !== 'CallForData2023')
  .map(([value, label]) => ({ value, label }));

const UploadListFilters = ({ filters, onFilterChange, belongsTo }) => {
  const debouncedOnFilterChange = React.useMemo(
    () => debounce(onFilterChange, 500),
    [onFilterChange],
  );

  return (
    <div
      className="mb-3"
      style={{
        display: 'flex',
        gap: '1rem',
        flexWrap: 'wrap',
      }}
    >
      <div style={{ width: '25rem' }}>
        <Form.Control
          onChange={({ target }) => {
            debouncedOnFilterChange('search', target.value);
          }}
          placeholder="Search..."
        />
      </div>
      {!belongsTo && (
        <div style={{ width: '25rem' }}>
          <BurnCenterFilter
            isMulti
            value={filters.burnCenters}
            onChange={(value) => onFilterChange('burnCenters', value)}
          />
        </div>
      )}
      <div style={{ width: '25rem' }}>
        <Select
          value={filters.types}
          onChange={(value) => {
            onFilterChange('types', value);
          }}
          placeholder="Type of Data"
          options={typeOptions}
          isMulti
          formatOptionLabel={(option, { context }) => (context === 'menu'
            ? option.label
            : DataUploadShortLabels[option.value])}
        />
      </div>
      <div style={{ width: '15rem' }}>
        <Select
          value={filters.fileTypes}
          onChange={(value) => {
            onFilterChange('fileTypes', value);
          }}
          placeholder="File Type"
          options={[
            { label: 'CSV', value: 'csv' },
            { label: 'XML', value: 'xml' },
            { label: 'XFR', value: 'xfr' },
          ]}
          isClearable
        />
      </div>
      {['from', 'to'].map((key) => (
        <div key={key} style={{ width: '10rem' }}>
          <DatePicker
            value={filters[key]}
            onChange={(value) => onFilterChange(key, value)}
            placeholderText={startCase(key)}
            customInput={<MaskedDateInput />}
            fullWidth
            isClearable
          />
        </div>
      ))}
    </div>
  );
};

UploadListFilters.propTypes = {
  filters: PropTypes.shape({
    types: PropTypes.arrayOf(PropTypes.string),
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    burnCenters: PropTypes.arrayOf(PropTypes.number),
    fileTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  belongsTo: PropTypes.number,
};

export default UploadListFilters;
