import { ActionTypes } from './actions';

export default (state = [], action) => {
  switch (action.type) {
    case ActionTypes.FETCHED:
      return action.data.items;
    default:
      return state;
  }
};
