import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBurnCenters } from 'store/burncenters/actions';

const useBurnCenters = () => {
  const dispatch = useDispatch();
  const burncenters = useSelector((state) => state.burncenters);

  useEffect(
    () => {
      if (burncenters.length) return;
      dispatch(fetchBurnCenters());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const burnCenterOptions = useMemo(
    () => burncenters?.map(({ id, name, hospitalName }) => ({
      value: id,
      label: [name, hospitalName].join(' - ').trim(),
    })),
    [burncenters],
  );

  return {
    burncenters,
    burnCenterOptions,
  };
};

export default useBurnCenters;
