import React from 'react';
import PropTypes from 'prop-types';

import useBurnCenters from 'hooks/useBurnCenters';

import Select from 'components/elements/FormInput/select/Select';

const BurnCenterFilter = ({ isMulti, onChange, value }) => {
  const { burnCenterOptions } = useBurnCenters();

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={`Burncenter${isMulti ? 's' : ''}`}
      options={burnCenterOptions}
      isMulti={isMulti}
      isClearable
    />
  );
};

BurnCenterFilter.propTypes = {
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node,
};

export default BurnCenterFilter;
