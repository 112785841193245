import api from 'api';
import { MAX_PAGE_SIZE } from 'utils/constants';

export const ActionTypes = { FETCHED: 'FETCHED-BURNCENTERS' };

export const fetchBurnCenters = () => (dispatch) => {
  api.getBurncenters({ limit: MAX_PAGE_SIZE }).then(({ data }) => dispatch({
    type: ActionTypes.FETCHED,
    data,
  }));
};
