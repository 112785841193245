import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Alert, Container, Stack } from 'react-bootstrap';

import api from 'api';
import useMountEffect from 'hooks/useMountEffect';
import useRequest from 'hooks/useRequest';
import { DataUploadLabels } from 'utils/enums';
import { formatUTCDate } from 'utils/dateTime';

import SectionWrapper from 'components/layout/SectionWrapper';
import { HistoryBackButton } from 'components/elements/BackButton';
import Loader from 'components/elements/Loader';

const formatFeedbackDate = (dateStr) => dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3');

const FileUploadFeedback = ({ metadata }) => {
  if (!metadata?.['x-amz-meta-feedback-total-records']) return null;

  return (
    <Stack gap={2}>
      <h5 className="mt-3 mb-0">Feedback</h5>
      <Stack direction="horizontal" gap={2}>
        <b>Total records received:</b>
        <span>{metadata['x-amz-meta-feedback-total-records']}</span>
      </Stack>
      {(metadata['x-amz-meta-feedback-admit-min']
        || metadata['x-amz-meta-feedback-admit-max']) && (
        <Stack direction="horizontal" gap={2}>
          <b>Admission date range:</b>
          <span>
            {`${formatFeedbackDate(metadata['x-amz-meta-feedback-admit-min'])} - ${formatFeedbackDate(metadata['x-amz-meta-feedback-admit-max'])}`}
          </span>
        </Stack>
      )}

      <i className="mt-3">
        There are currently four fields used for inclusion in benchmark reports
        (more are used for risk adjusted models).
      </i>
      <i className="mb-3">A summary of the four fields is listed below:</i>

      <Stack direction="horizontal" gap={2}>
        <b>Cases missing patient age:</b>
        <span>{metadata['x-amz-meta-feedback-missing-pt-age']}</span>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <b>Cases missing patient sex:</b>
        <span>{metadata['x-amz-meta-feedback-missing-pt-sex']}</span>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <b>Cases missing admission date:</b>
        <span>{metadata['x-amz-meta-feedback-missing-dt']}</span>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <b>Cases missing discharge disposition:</b>
        <span>{metadata['x-amz-meta-feedback-missing-dish']}</span>
      </Stack>
    </Stack>
  );
};

FileUploadFeedback.propTypes = {
  metadata: PropTypes.shape({
    'x-amz-meta-feedback-total-records': PropTypes.string,
    'x-amz-meta-feedback-missing-dish': PropTypes.string,
    'x-amz-meta-feedback-admit-min': PropTypes.string,
    'x-amz-meta-feedback-admit-max': PropTypes.string,
    'x-amz-meta-feedback-missing-pt-age': PropTypes.string,
    'x-amz-meta-feedback-missing-pt-sex': PropTypes.string,
    'x-amz-meta-feedback-missing-dt': PropTypes.string,
  }),
};

const UploadInfoContent = () => {
  const { uploadId } = useParams();

  const {
    doRequest: fetchUpload,
    data: uploadData,
    error,
  } = useRequest(api.getUpload);

  const {
    doRequest: fetchFiles,
    data: filesData,
    error: filesError,
  } = useRequest(api.getUploadsFiles);

  useMountEffect(() => {
    fetchUpload(uploadId);
    fetchFiles({ DataUploadId: uploadId });
  });

  if (error || filesError) {
    return (
      <Alert className="mt-3 d-flex justify-content-between" variant="danger">
        Error loading report
      </Alert>
    );
  }
  if (!(uploadData && filesData)) return <Loader />;

  return (
    <Stack gap={2}>
      <Stack direction="horizontal" gap={2}>
        <b>Burn Center</b>
        <span>{uploadData.burnCenterName}</span>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <b>Description:</b>
        <span>{uploadData.description}</span>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <b>Submitted Date:</b>
        <span>{formatUTCDate(uploadData.createdAt, 'YYYY-MM-DD LTS UTC')}</span>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <b>Submitted by:</b>
        <span>{uploadData.userEmail}</span>
      </Stack>
      <Stack direction="horizontal" gap={2}>
        <b>Type of Data:</b>
        <span>{DataUploadLabels[uploadData.type]}</span>
      </Stack>
      {filesData.data.length > 1 && (
        <b>
          <i>Submitted files</i>
        </b>
      )}
      <Stack gap={2}>
        {filesData.data.map(({ id, filename, metadata }) => (
          <Stack key={id} gap={2}>
            <Stack direction="horizontal" gap={2}>
              <b>Filename:</b>
              <span>{filename}</span>
            </Stack>
            <FileUploadFeedback metadata={metadata} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

const UploadInfoPage = () => (
  <SectionWrapper title="Upload Info">
    <div className="mb-2">
      <HistoryBackButton />
    </div>
    <Container className="p-3 bg-light" fluid>
      <UploadInfoContent />
    </Container>
  </SectionWrapper>
);

export default UploadInfoPage;
